
<template>
  <div class="body-content" id="fullScreenDiv">
    <div class="loading" v-show="loading"><a-spin size="large" /></div>
    <div class="body-top">
      <a-month-picker v-model:value="month" format="YYYY-MM" @change="monthChange" :getCalendarContainer="e => e.parentNode">
        <a style="color: #fff; font-size: 18px">{{ month }}
          <CaretRightOutlined />
        </a>
      </a-month-picker>
      <div style="height: 100%; flex: 1" @dblclick="fullScreen"></div>
      <span style="color: #fff">{{ dateData }}</span>
    </div>
    <!-- 占位符 -->
    <div style="height: 9%; display: flex; justify-content: center; align-items: center">
      <!-- <img src="@/assets/images/monitoring/title.png" /> -->
    </div>
    <div class="content-div">
      <!-- 省份准时率排名 -->
      <div class="left-div">
        <a-space class="search-form" :size="4">
          <a-select v-model:value="searchForm.dispatchWay" :getPopupContainer="e => e.parentNode" style="width: 100px" placeholder="全部" @change="searchChange">
            <template #suffixIcon>
              <CaretDownOutlined style="color: #fff; font-size: 24px" />
            </template>
            <a-select-option :value="-1">总发运</a-select-option>
            <a-select-option :value="0">纯运力</a-select-option>
            <a-select-option :value="1">混合的</a-select-option>
            <a-select-option :value="2">纯专线</a-select-option>
          </a-select>
          <a-select v-model:value="searchForm.orderBusiness" :getPopupContainer="e => e.parentNode" style="width: 100px" placeholder="业务方" @change="searchChange">
            <template #suffixIcon>
              <CaretDownOutlined style="color: #fff; font-size: 24px" />
            </template>
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="0">华月</a-select-option>
            <a-select-option :value="1">专线</a-select-option>
          </a-select>
          <a-select v-model:value="searchForm.vehicleNum" :getPopupContainer="e => e.parentNode" style="width: 100px" placeholder="台量类型" @change="searchChange">
            <template #suffixIcon>
              <CaretDownOutlined style="color: #fff; font-size: 24px" />
            </template>
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="1">散车</a-select-option>
            <a-select-option :value="2">批量车</a-select-option>
          </a-select>
          <a-select v-model:value="searchForm.transportDistance" :getPopupContainer="e => e.parentNode" style="width: 100px" placeholder="运输类型" @change="searchChange">
            <template #suffixIcon>
              <CaretDownOutlined style="color: #fff; font-size: 24px" />
            </template>
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="1">短驳</a-select-option>
            <a-select-option :value="2">同省</a-select-option>
            <a-select-option :value="3">跨省</a-select-option>
            <a-select-option :value="4">跨国</a-select-option>
          </a-select>
          <a-popover title="" trigger="click" placement="bottom" overlayClassName="ant-suy-table-column-setting-overlay">
            <template #content>
              <div v-for="item in checkedList" :key="item.id">
                <a-checkbox v-model:checked="item.checked">{{ item.title }}</a-checkbox>
              </div>
              <div style="margin-top:5px" class="flex ju-end"><a-button @click="saveCheckedList" size="small" type="primary">确认</a-button></div>
            </template>
            <a-select  :getPopupContainer="e => e.parentNode" style="width: 100px;z-index:99" placeholder="剔除类型" @change="searchChange" :open="false">
              <template #suffixIcon>
                <CaretDownOutlined style="color: #fff; font-size: 24px" />
              </template>
            </a-select>
          </a-popover>

        </a-space>
        <div class="left-content">
          <div class="province-data">
            <div class="title-div">
              <div class="data-title">省份准时率排名</div>
              <div style="color: #fff; margin-bottom: 5px">
                <a @click="openDetail(0)"><img src="@/assets/images/monitoring/more.png" /></a>
              </div>
            </div>
            <div class="data-content">
              <div class="ranking">
                <div class="ranking-data-div" v-for="(item, index) in data.provincesRanking.topData" :key="index">
                  <div class="ranking-label">第{{ index + 1 }}名</div>
                  <div class="data-show">
                    <span>{{ item.proportion + "%" }}</span>
                  </div>
                  <div style="color: #fff">{{ item.name }}</div>
                </div>
              </div>
              <div class="ranking-reversal">
                <div class="ranking-data-div" v-for="(item, index) in data.provincesRanking.lastData" :key="index">
                  <div class="ranking-label-reversal">倒数第{{ index + 1 }}名</div>
                  <div class="data-show">
                    <span>{{ item.proportion + "%" }}</span>
                  </div>
                  <div style="color: #fff">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="department-data">
            <div class="title-div">
              <div class="data-title">业务部门准时率</div>
              <div style="color: #fff; margin-bottom: 5px">
                <a @click="openDetail(1)"><img src="@/assets/images/monitoring/more.png" /></a>
              </div>
            </div>
            <div id="department" style="width: 100%; height: 85%"></div>
          </div>
        </div>
      </div>
      <div class="center-div">
        <div class="center-china">
          <div id="china_map"></div>
        </div>
        <!-- 年度总准时率统计 -->
        <div class="center-data">
          <div class="title-div">
            <div class="data-title">年度总准时率统计</div>
            <div style="color: #fff; margin-bottom: 5px">
              <a @click="openDetail(2)"><img src="@/assets/images/monitoring/more.png" /></a>
            </div>
          </div>
          <div id="yearData" style="width: 100%; flex: 1"></div>
        </div>
      </div>
      <div class="right-content">
        <!-- 线路准时率前5名/后5名 -->
        <div style="height:70%">
          <div class="title-div">
            <div class="data-title">线路准时率前5名</div>
            <div style="color: #fff; margin-bottom: 5px">
              <a @click="openDetail(3)"><img src="@/assets/images/monitoring/more.png" /></a>
            </div>
          </div>
          <table class="router-data">
            <thead class="table-title">
              <tr>
                <td class="table-td">排名</td>
                <td class="table-td">线路</td>
                <td class="table-td">发运台量</td>
                <td class="table-td">超时台量</td>
                <td class="table-td">准时率</td>
              </tr>
            </thead>
            <tbody style="width: 100%;">
              <tr style="width: 100%;" v-for="(item, index) in data.lineRanking.topList" :key="index">
                <td class="table-td table-td-number">{{ item.rank }}</td>
                <td class="table-td" style="width:30%;">
                  <a-tooltip :getPopupContainer="e => e.parentNode">
                    <template #title>{{ item.lineName }}</template>
                    {{ item.lineName }}
                  </a-tooltip>
                </td>
                <td class="table-td">{{ item.transNum }}</td>
                <td class="table-td">{{ item.timeoutNum }}</td>
                <td class="table-td">{{ item.punctuality }}</td>
              </tr>
              <tr>
                <td colspan="5" class="table-td table-td-number" style="font-size: 16px">
                  排名后5名
                </td>
              </tr>
              <tr style="width: 100%;" v-for="(item, index) in data.lineRanking.lastList" :key="index" class="last-data">
                <td class="table-td table-td-number" style="font-size: 18px; color: #ffe825">
                  {{ item.rank }}
                </td>
                <td class="table-td" style="width:30%;">
                  <a-tooltip :getPopupContainer="e => e.parentNode">
                    <template #title>{{ item.lineName }}</template>
                    {{ item.lineName }}
                  </a-tooltip>
                </td>
                <td class="table-td">{{ item.transNum }}</td>
                <td class="table-td">{{ item.timeoutNum }}</td>
                <td class="table-td">{{ item.punctuality }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- 发运准时率 -->
        <div style="height:30%;display: flex;flex-direction: column;justify-content: center;margin:auto;">
          <div class="title-div">
            <div class="data-title">发运准时率</div>
            <div style="color: #fff; margin-bottom: 5px">
              <a @click="openDetail(4)"><img src="@/assets/images/monitoring/more.png" /></a>
            </div>
          </div>
          <div class="trans-div">
            <div :style="{ color: colors[item.dispatchWay?.value + 1] }" class="trans-content-div total-punctuality" v-for="(item, index) in data.transList" :key="index">
              <div class="trans-title">{{ item.dispatchWay?.label }}</div>
              <div class="trans-content">
                <div class="data-div" style="flex:2">
                  <div class="trans-data-div">
                    <div class="trans-label">发运台量</div>
                    <div class="trans-num">{{ item.transNum }}</div>
                  </div>
                  <div class="trans-data-div">
                    <div class="trans-label">超时台量</div>
                    <div class="trans-num">{{ item.timeoutNum }}</div>
                  </div>
                </div>
                <div class="proportion-data" style="flex:1">
                  <a-progress trailColor="#14437c" stroke-linecap="square" :format="(value) => `${value}%`" :strokeColor="colors[item.dispatchWay?.value + 1]" type="circle" :percent="item.punctuality" :width="55" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model:visible="detailShow" width="40%" :title="detailTitle" footer="" :getContainer="false" :bodyStyle="{ paddingTop: '10px' }">
      <div>
        <ProvinceTable ref="provinceRef" v-if="showNum === 0" :dataList="provinceDataList" :searchFormData="searchForm" />
        <DepartmentTable ref="departmentRef" v-if="showNum === 1" :searchFormData="searchForm" />
        <YearTable ref="yearTableRef" v-if="showNum === 2" :searchFormData="searchForm" />
        <LineTable ref="lineTableRef" v-if="showNum === 3" :searchFormData="searchForm" />
        <TransTable ref="transTableRef" v-if="showNum === 4" :searchFormData="searchForm" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { getChinaData, departmentData, yearStatisticsData } from '../echarts/echarts.js'
import {
  CaretDownOutlined,
  CaretRightOutlined,
} from '@ant-design/icons-vue'
import { getCompanyAll } from '@/api/dataCenter/monitoring'
import { getTimeYMD, getTime } from '@/utils/util'
import ProvinceTable from './provinceTable'
import DepartmentTable from './departmentTable'
import YearTable from './yearTable'
import LineTable from './lineTable'
import TransTable from './transTable'
// 引入screenfull
import screenfull from 'screenfull'

export default {
  components: {
    CaretDownOutlined,
    CaretRightOutlined,
    ProvinceTable,
    DepartmentTable,
    YearTable,
    LineTable,
    TransTable
  },
  setup () {
    const transTableRef = ref(null)
    const lineTableRef = ref(null)
    const yearTableRef = ref(null)
    const departmentRef = ref(null)
    const provinceRef = ref(null)
    const state = reactive({
      checkedList: [
        {
          title:'客户原因',
          value: 1,
          checked: false
        },
        {
          title:'自然因素',
          value: 3,
          checked: false
        },
        {
          title:'单台凑板',
          value: 10,
          checked: false
        }
      ],
      isFullscreen: false,
      detailShow: false,
      loading: false,
      showNum: null,
      queryId: null,
      timer: null,
      flag: 0,
      detailTitle: '',
      month: '',
      dateData: '',
      orgNameList: [],
      orgDataList: [],
      yearTitles: [],
      yearData: [],
      provinceDataList: [],
      data: {
        // 大数据渲染
        transList: [],
        lineRanking: {
          topList: [],
          lastList: []
        },
        provincesRanking: {
          topData: [],
          lastData: []
        }
      },
      mirrorData: {},
      colors: {
        0: '#26c590',
        1: '#6054ff',
        2: '#22e2fe',
        3: '#ffc023'
      },
      searchForm: {
        yearMonth: null,
        year: null,
        dispatchWay: -1,
        transportDistance: null,
        orderBusiness: null,
        excludeTypes: null
      }
    })
    onMounted(() => {
      clearInterval(state.timer)
      state.timer = setInterval(() => {
        state.dateData = getTime(2)
      }, 1000)
      document.querySelector('.ant-page-container').style.height = '95%'
      state.mirrorData = JSON.stringify(state.data)
      state.yearMonth = getTimeYMD().split('-')[0] + '-' + getTimeYMD().split('-')[1]
      state.searchForm.year = state.yearMonth.split('-')[0]
      loadData()
      setInterval(() => {
        loadData()
      }, 600000)
    })
    const saveCheckedList = () => {
      let list = []
      state.checkedList.forEach(item => {
        if (item.checked) {
          list.push(item.value)
        }
      })
      state.searchForm.excludeTypes = list
      searchChange()
    }
    const yearSearch = (month) => {
      clearInterval()
      state.searchForm.yearMonth = month
      state.data = JSON.parse(state.mirrorData)
      loadData()
    }
    const chinaSearch = (queryId) => {
      state.data = JSON.parse(state.mirrorData)
      state.flag++
      if (state.queryId === queryId && state.flag < 2) loadData()
      else {
        loadData(queryId)
        state.flag = 0
      }
      state.queryId = queryId
    }
    const loadData = (queryId) => {
      state.loading = true
      // init
      state.data.provincesRanking.topData = []
      state.data.provincesRanking.lastData = []
      state.data.lineRanking.topList = []
      state.data.lineRanking.lastList = []
      state.data.transList = []
      getCompanyAll({
        ...state.searchForm,
        startProvince: queryId || null
      }).then((res) => {
        if (res.code === 10000) {
          state.month = res.data.month
          state.searchForm.yearMonth = res.data.month
          // 省份准时率
          state.provinceDataList = res.data.province
          res.data.province.forEach((item, index) => {
            if (index + 1 <= 3) {
              state.data.provincesRanking.topData.push({
                ranking: item.rank,
                name: item.name,
                proportion: item.punctuality
              })
            }
            if (index + 1 > res.data.province.length - 3) {
              state.data.provincesRanking.lastData.unshift({
                ranking: item.rank,
                name: item.name,
                proportion: item.punctuality
              })
            }
          })
          // 业务部门准时率
          const orgNameList = []
          const dataList = []
          res.data.org.forEach((item) => {
            orgNameList.push(item.orgName)
            dataList.push({
              value: Number(item.rate),
              proportionNum: Number(item.rate),
              totalNum: item.total, // 正常完成台量
              timeoutNum: item.overDueNum, // 超时台量
              punctuality: item.rate // 准时率占比
            })
          })
          // 地图echarts
          const chinaData = res.data.map
          // 年度准时率
          const data = []
          const yearTitles = []
          res.data.year.forEach((item) => {
            yearTitles.push(item.month)
            data.push({
              value: Number(item.rate),
              month: item.month,
              transNum: item.total, // 发运台量
              timeoutNum: item.overDueNum, // 超时台量
              punctuality: item.rate // 准时率
            })
          })
          // 线路准时率
          res.data.line.forEach((item, index) => {
            if (index + 1 <= 5) {
              state.data.lineRanking.topList.push({
                rank: item.rank,
                lineName: item.lineName,
                transNum: item.total, // 发运台量
                timeoutNum: item.overDueNum, // 超时台量
                punctuality: item.rate + '%' // 准时率
              })
            }
            if (index + 1 > res.data.line.length - 5) {
              state.data.lineRanking.lastList.push({
                rank: item.rank,
                lineName: item.lineName,
                transNum: item.total, // 发运台量
                timeoutNum: item.overDueNum, // 超时台量
                punctuality: item.rate + '%' // 准时率
              })
            }
          })
          // 发运准时率
          res.data.trans.forEach((item) => {
            state.data.transList.push({
              dispatchWay: item.dispatchWay,
              timeoutNum: item.overDueNum,
              transNum: item.total,
              punctuality: Number(item.rate)
            })
          })
          setTimeout(() => {
            state.orgNameList = orgNameList
            state.orgDataList = dataList
            state.yearTitles = yearTitles
            state.yearData = data
            getChinaData('china_map', chinaData, chinaSearch)
            departmentData('department', orgNameList, dataList)
            yearStatisticsData('yearData', yearTitles, data, yearSearch)
          }, 100)
        }
      }).finally(() => {
        setTimeout(() => {
          state.loading = false
        }, 500)
      })
    }
    const openDetail = (num) => {
      state.showNum = num
      state.detailShow = true
      switch (num) {
        case 0:
          state.detailTitle = '省份准时率排名'
          setTimeout(() => {
            provinceRef.value.setData()
            provinceRef.value.loadData()
          }, 10)
          break
        case 1:
          state.detailTitle = '业务部门准时率'
          setTimeout(() => {
            departmentRef.value.setData()
            departmentRef.value.loadData()
          }, 10)
          break
        case 2:
          state.detailTitle = '年度总准时率'
          setTimeout(() => {
            yearTableRef.value.setData()
            yearTableRef.value.loadData()
          }, 10)
          break
        case 3:
          state.detailTitle = '线路准时率'
          setTimeout(() => {
            lineTableRef.value.setData()
            lineTableRef.value.loadData()
          }, 10)
          break
        case 4:
          state.detailTitle = '发运准时率'
          setTimeout(() => {
            transTableRef.value.setData()
            transTableRef.value.loadData()
          }, 10)
          break
      }
    }
    const searchChange = () => {
      yearSearch(state.month)
    }
    const monthChange = (e, v) => {
      state.month = v
      state.searchForm.year = v.split('-')[0]
      yearSearch(state.month)
    }
    const fullScreen = () => {
      // setTimeout(() => {
      //   // getChinaData("china_map", chinaData, chinaSearch);
      //   departmentData("department", state.orgNameList, state.orgDataList);
      //   yearStatisticsData("yearData", state.yearTitles, state.yearData);
      // }, 500);
      // 先使用screenfull.enabled方法判断是否支持screenfull
      // 如果不允许进入全屏，发出不允许提示 浏览器不能全屏
      if (!screenfull.enabled) {
        return false
      }
      const element = document.getElementById('fullScreenDiv')
      // 调用 screenfull.toggle() 可以双向切换全屏与非全屏
      // screenfull.toggle()
      console.log('全屏');
      screenfull.request(element)
    }
    return {
      ...toRefs(state),
      provinceRef,
      departmentRef,
      yearTableRef,
      lineTableRef,
      transTableRef,
      loadData,
      fullScreen,
      openDetail,
      yearSearch,
      monthChange,
      searchChange,
      saveCheckedList
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';

:deep(.ant-page-container) {
  height: 100%;
}

.ant-suy-table-column-setting-overlay {
  :global(.ant-popover-inner-content) {
    margin-top: -10px;
  }
}
</style>